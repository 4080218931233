// login
export const STRINGS_LOGIN = "Login"
export const STRINGS_EMAIL = "Email"
export const STRINGS_PASSWORD = "Password"
export const STRINGS_NUB_VISION_MDM = "Nub Vision MDM"

// home
export const STRINGS_BOXES = "Boxes";
export const STRINGS_SEARCH = "Search";
export const STRINGS_BOX_DETAILS = "Box Details";
export const STRINGS_NAME = "Name";
export const STRINGS_DELETE = "Delete";
export const STRINGS_REFRESH = "Refresh";
export const STRINGS_AVAILABILITY = "Availability";
export const STRINGS_IP_ADDRESS = "IP Address";
export const STRINGS_REGISTRATION_DATE = "Registration Date";
export const STRINGS_EDIT_NAME = "Edit Name";
export const STRINGS_ONLINE = "Online";
export const STRINGS_OFFLINE = "Offline";
export const STRINGS_INSTALLED_APPS = "Installed Apps";
export const STRINGS_NOTHING_TO_SHOW = "Nothing to show"
export const STRINGS_CANCEL = "Cancel"
export const STRINGS_OK = "Ok"
export const STRINGS_DELETE_BOX = "Delete Box";
export const STRINGS_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_BOX = "Are you sure you want to delete this box?";


// errors
export const STRINGS_404_NOT_FOUND = "404 Not Found";
export const STRINGS_EMAIL_NOT_VALID = "Email not valid";
export const STRINGS_EMAIL_REQUIRED = "Email is required";
export const STRINGS_PASSWORD_REQUIRED = "Password is required";