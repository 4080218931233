import { Box, Button, Stack, Tooltip } from "@mui/material";
import {
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_SECONDARY,
  COLOR_VERY_LIGHT_GRAY,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { InstalledApp } from "../../../domain/model/Box";
import { CustomCardView } from "../../component/CustomCardView";
import { CustomImageView } from "../../component/CustomImageView";
import { CustomTextView } from "../../component/CustomTextView";

interface p {
  installedApp: InstalledApp;
}

export function InstalledAppsListItemView(p: p) {
  const installedApp = p.installedApp;
  return (
    <CustomCardView
      background={COLOR_MAIN}
      padding={0}
      height={260}
      children={
        <Stack height={"100%"}>
          <Box>
            <CustomImageView
              src={installedApp.image}
              width="100%"
              height={130}
              scale="contain"
              props={{
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                background: COLOR_WHITE,
              }}
            />
          </Box>
          <Stack
            p={2}
            justifyContent={"space-between"}
            height={"100%"}
            spacing={1}
          >
            <Tooltip title={installedApp.name}>
              <Button sx={{ p: 0 }}>
                <CustomTextView
                  text={installedApp.name}
                  textColor={COLOR_WHITE}
                  fontWeight="bold"
                  fontSize={22}
                  props={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    overflowWrap: "break-word",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    textTransform: "none",
                  }}
                />
              </Button>
            </Tooltip>
            <Tooltip title={installedApp.packageName}>
              <Button sx={{ p: 0 }}>
                <CustomTextView
                  text={installedApp.packageName}
                  textColor={COLOR_LIGHT_GRAY}
                  fontWeight="300"
                  fontSize={16}
                  props={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    overflowWrap: "break-word",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    textTransform: "none",
                  }}
                />
              </Button>
            </Tooltip>

            <Tooltip title={installedApp.version}>
              <Button sx={{ p: 0 }}>
                <CustomTextView
                  text={installedApp.version}
                  textColor={COLOR_LIGHT_GRAY}
                  fontSize={14}
                  props={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    overflowWrap: "break-word",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    textTransform: "none",
                  }}
                />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      }
    />
  );
}
