import { Box, Toolbar, Typography } from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import { STRINGS_404_NOT_FOUND } from "../../data/strings/Strings";
import { ErrorTwoTone } from "@mui/icons-material";
import { COLOR_MAIN, COLOR_WHITE } from "../../data/colors/Colors";
import { CustomTextView } from "./CustomTextView";

export function Error404View() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: COLOR_MAIN,
      }}
    >
      <ErrorTwoTone fontSize="large" sx={{ color: "red" }} />
      <CustomTextView
        text={STRINGS_404_NOT_FOUND}
        variant="h5"
        fontWeight="bold"
        textColor={COLOR_WHITE}
      />
      <Toolbar/>
    </Box>
  );
}
