import NetworkApi from "../../data/network/NetworkApi";
import NetworkApiImpl from "../../data/network/NetworkApiImpl";
import { AuthRepository } from "../../data/repository/auth/AuthRepository";
import { AuthRepositoryImpl } from "../../data/repository/auth/AuthRepositoryImpl";
import { BoxRepository } from "../../data/repository/box/BoxRepository";
import { BoxRepositoryImpl } from "../../data/repository/box/BoxRepositoryImpl";
import { DeleteBoxUseCase, DeleteBoxUseCaseImpl } from "../../domain/useCase/box/delete/DeleteUseCase";
import { GetBoxUseCase, GetBoxUseCaseImpl } from "../../domain/useCase/box/get/GetUseCase";
import { GetAllBoxesUseCase, GetAllBoxesUseCaseImpl } from "../../domain/useCase/box/getAll/GetAllUseCase";
import { SearchBoxesUseCase, SearchBoxesUseCaseImpl } from "../../domain/useCase/box/search/SearchUseCase";
import { UpdateBoxUseCase, UpdateBoxUseCaseImpl } from "../../domain/useCase/box/update/UpdateUseCase";
import { LoginUseCase, LoginUseCaseImpl } from "../../domain/useCase/Login/LoginUseCase";
import { provideApiBuilder } from "./NetworkModule";

function provideNetworkApi(): NetworkApi {
  return new NetworkApiImpl(provideApiBuilder());
}

// auth
function provideAuthRepository(): AuthRepository {
  return new AuthRepositoryImpl(provideNetworkApi());
}
export function provideLoginUseCase(): LoginUseCase {
  return new LoginUseCaseImpl(provideAuthRepository());
}

// box
function provideBoxRepository(): BoxRepository {
  return new BoxRepositoryImpl(provideNetworkApi());
}
export function provideGetAllBoxesUseCase(): GetAllBoxesUseCase {
  return new GetAllBoxesUseCaseImpl(provideBoxRepository());
}
export function provideGetBoxUseCase(): GetBoxUseCase {
  return new GetBoxUseCaseImpl(provideBoxRepository());
}
export function provideDeleteBoxUseCase(): DeleteBoxUseCase {
  return new DeleteBoxUseCaseImpl(provideBoxRepository());
}
export function provideUpdateBoxUseCase(): UpdateBoxUseCase {
  return new UpdateBoxUseCaseImpl(provideBoxRepository());
}
export function provideSearchBoxesUseCase(): SearchBoxesUseCase {
  return new SearchBoxesUseCaseImpl(provideBoxRepository());
}
