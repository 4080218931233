import { Button, Stack } from "@mui/material";
import { Box } from "../../../domain/model/Box";
import { CustomCardView } from "../../component/CustomCardView";
import { CustomTextView } from "../../component/CustomTextView";
import { ROUTE_ENDPOINTS } from "../../../data/constant/RouteConstants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  COLOR_BLACK,
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_SECONDARY,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { Circle } from "@mui/icons-material";

interface p {
  box: Box;
  index: number;
  handleToggleDrawer?: () => void;
}
export function BoxesListItemView(p: p) {
  const location = useLocation();
  const navigate = useNavigate();
  const box = p.box;
  return (
    <CustomCardView
      width={"100%"}
      padding={0}
      background={
        location.pathname === `${ROUTE_ENDPOINTS.HOME_BOXES}/${box._id}`
          ? COLOR_BLACK
          : COLOR_SECONDARY
      }
      children={
        <Button
          onClick={() => {
            navigate(`${ROUTE_ENDPOINTS.HOME_BOXES}/${box._id}`);
            if (p.handleToggleDrawer) {
              p.handleToggleDrawer();
            }
          }}
          sx={{
            p: 2,
            borderRadius: 4,
            "&:hover": {
              backgroundColor: COLOR_LIGHT_GRAY,
            },
            "& .MuiTouchRipple-child": {
              backgroundColor: COLOR_LESS_BLACK,
            },
          }}
          fullWidth
        >
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"start"}
            sx={{ textTransform: "none" }}
          >
            <Stack direction={"column"} alignItems={"start"} maxWidth={"90%"}>
              <CustomTextView
                text={`#${p.index + 1}`}
                textColor={COLOR_WHITE}
                fontWeight="bold"
                fontSize={18}
              />
              <CustomTextView
                text={box.name}
                textColor={COLOR_WHITE}
                fontSize={27}
                fontWeight="bold"
                props={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
              />
            </Stack>
          </Stack>
        </Button>
      }
    />
  );
}
