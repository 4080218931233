import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Box } from "../../../domain/model/Box";
import { GetAllBoxesUseCase } from "../../../domain/useCase/box/getAll/GetAllUseCase";
import { State } from "../../../domain/model/ResponseState";
import { SearchBoxesUseCase } from "../../../domain/useCase/box/search/SearchUseCase";

export function HomeViewModel(getAllBoxesUseCase: GetAllBoxesUseCase, searchBoxesUseCase: SearchBoxesUseCase) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [boxes, setBoxes] = useState<Array<Box> | undefined>([]);

  useEffect(() => {
    getBoxes();
  }, []);

  async function getBoxes() {
    setIsLoading(true);
    const response = await getAllBoxesUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        setBoxes(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }


  async function searchBoxes(name: string) {
    if (!name) {
      getBoxes()
      return
    }
    setIsLoading(true);
    const response = await searchBoxesUseCase.invoke(name);
    switch (response.responseState) {
      case State.Success:
        setBoxes(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    isLoading,
    boxes,
    searchBoxes
  };
}
