import { AppBar, Toolbar, IconButton, Button, Box, Stack } from "@mui/material";
import SharedPreferences from "../../core/utils/SharedPreferences";
import { Logout } from "@mui/icons-material";
import { STRINGS_NUB_VISION_MDM } from "../../data/strings/Strings";
import { COLOR_SECONDARY, COLOR_WHITE } from "../../data/colors/Colors";
import { CustomTextView } from "./CustomTextView";
import { CustomImageView } from "./CustomImageView";
import { ROUTE_ENDPOINTS } from "../../data/constant/RouteConstants";

interface p {
  handleDrawerToggle: () => void;
}
export function AppToolbar(p: p) {
  return (
    <AppBar
      position="fixed"
      sx={{
        background: COLOR_SECONDARY,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        maxWidth: "100%"
      }}
    >
      <Toolbar>
        <Stack
          direction={"row"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <IconButton
            onClick={p.handleDrawerToggle}
            sx={{
              ml: 0,
              mr: 0,
              display: { sm: "none" },
              p: 1,
            }}
          >
            <CustomImageView
              src={require("../../assets/images/choose-android-box-cartoonish-image.png")}
              width="34px"
              height="34px"
              props={{ borderRadius: 12 }}
            />
          </IconButton>
          <Button
            LinkComponent={"a"}
            href={ROUTE_ENDPOINTS.HOME_BOXES}
            sx={{
              p: 0,
              "&:hover": {
                backgroundColor: "transparent",
                "& .MuiTouchRipple-child": {
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            <CustomTextView
              text={STRINGS_NUB_VISION_MDM}
              variant="h5"
              fontWeight="bold"
              textColor={COLOR_WHITE}
              props={{ textTransform: "none" }}
            />
          </Button>

          <IconButton
            color="inherit"
            sx={{ p: 1 }}
            onClick={() => {
              SharedPreferences.clear();
            }}
          >
            <Logout />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
