import { BoxRepository } from "../../../../data/repository/box/BoxRepository";
import { Box } from "../../../model/Box";
import { ResponseState, State } from "../../../model/ResponseState";

export interface SearchBoxesUseCase {
  invoke: (name: string) => Promise<ResponseState<Array<Box>>>;
}

export class SearchBoxesUseCaseImpl implements SearchBoxesUseCase {
  private boxRepository: BoxRepository;

  constructor(boxRepository: BoxRepository) {
    this.boxRepository = boxRepository;
  }

  async invoke(name: string): Promise<ResponseState<Array<Box>>> {
    try {
      let response = await this.boxRepository.search(name);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
