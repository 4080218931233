import { BoxRepository } from "../../../../data/repository/box/BoxRepository";
import { Box } from "../../../model/Box";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetBoxUseCase {
  invoke: (id: string) => Promise<ResponseState<Box>>;
}

export class GetBoxUseCaseImpl implements GetBoxUseCase {
  private boxRepository: BoxRepository;

  constructor(boxRepository: BoxRepository) {
    this.boxRepository = boxRepository;
  }

  async invoke(id: string): Promise<ResponseState<Box>> {
    try {
      let response = await this.boxRepository.get(id);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
