import { Auth, AccessToken } from "../../../domain/model/Auth";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { AuthRepository } from "./AuthRepository";

export class AuthRepositoryImpl implements AuthRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }

  login(auth: Auth): Promise<Response<AccessToken>> {
    return this.apiService.login(auth);
  }

}
