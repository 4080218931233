import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import { LoginViewModel } from "./LoginViewModel";
import { LOGIN_PAGE_BACKGROUND_IMAGE } from "../../../data/constant/Constants";
import { useEffect } from "react";
import { CustomImageView } from "../../component/CustomImageView";
import useWindowDimensions from "../../../core/utils/UseWindowDimensions";
import { COLOR_LESS_BLACK, COLOR_WHITE } from "../../../data/colors/Colors";
import LoginForm from "./LoginForm";
import { provideLoginUseCase } from "../../di/UseCasesModule";

export default function LoginView() {
  const { login, isLoading, setIsLoading } = LoginViewModel(
    provideLoginUseCase()
  );
  const { width } = useWindowDimensions();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <Box>
      <Grid container>
        <Grid xs={0} sm={0} md={6} xl={8}>
          <Box
            sx={{
              height: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              background: COLOR_LESS_BLACK,
            }}
          >
            <CustomImageView
              width={width > 900 ? "100%" : "0%"}
              height={width > 900 ? "100%" : "0%"}
              src={LOGIN_PAGE_BACKGROUND_IMAGE}
              props={{ borderTopRightRadius: 80, borderBottomRightRadius: 80 }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={6}
          xl={4}
          sx={{ background: COLOR_LESS_BLACK }}
        >
          <LoginForm onSubmit={login} />
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: COLOR_WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
