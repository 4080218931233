import { Box, CircularProgress } from "@mui/material";
import {
  COLOR_LESS_BLACK,
  COLOR_SECONDARY,
  COLOR_WHITE,
} from "../../data/colors/Colors";

interface p {
  minHeight?: string;
}
export function LoadingView(p: p) {
  return (
    <Box
      sx={{
        minHeight: p.minHeight ?? "40vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress sx={{ color: COLOR_WHITE }} />
    </Box>
  );
}
