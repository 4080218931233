import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import SharedPreferences from "../../core/utils/SharedPreferences";
import { STORAGE_KEYS } from "../../data/constant/Constants";
import { ROUTE_ENDPOINTS } from "../../data/constant/RouteConstants";
import LoginView from "../pages/login/LoginView";
import HomeView from "../pages/home/HomeView";

export default function Router() {
  const getLoginToken = () =>
    SharedPreferences.getString(STORAGE_KEYS.TOKEN) !== null;

  const [isLoggedIn, setIsLoggedIn] = useState(getLoginToken());

  window.addEventListener(STORAGE_KEYS.TOKEN, () => {
    setIsLoggedIn(getLoginToken());
  });

  function PublicRoute(path: string, element: React.ReactNode) {
    return (
      <Route
        path={path}
        element={isLoggedIn ? <Navigate to={ROUTE_ENDPOINTS.HOME_BOXES} /> : element}
      />
    );
  }

  function AuthRoute(path: string, element: React.ReactNode) {
    return (
      <Route
        path={path}
        element={isLoggedIn ? element : <Navigate to={ROUTE_ENDPOINTS.LOGIN} />}
      />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {PublicRoute(ROUTE_ENDPOINTS.LOGIN, <LoginView />)}
        {AuthRoute(ROUTE_ENDPOINTS.HOME, <HomeView />)}
        <Route path="*" element={<Navigate to={ROUTE_ENDPOINTS.LOGIN} />} />
      </Routes>
    </BrowserRouter>
  );
}
