import { useEffect, useState } from "react";
import {
  Box,
  getBoxFromId,
  WebsocketModelType,
} from "../../../../domain/model/Box";
import { useSnackbar } from "notistack";
import { GetBoxUseCase } from "../../../../domain/useCase/box/get/GetUseCase";
import { State } from "../../../../domain/model/ResponseState";
import { UpdateBoxUseCase } from "../../../../domain/useCase/box/update/UpdateUseCase";
import { DeleteBoxUseCase } from "../../../../domain/useCase/box/delete/DeleteUseCase";
import { useNavigate } from "react-router-dom";
import { ROUTE_ENDPOINTS } from "../../../../data/constant/RouteConstants";
import moment from "moment";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { WEBSOCKET_URL } from "../../../../data/constant/ApiConstants";

export function BoxDetailsViewModel(
  getBoxUseCase: GetBoxUseCase,
  updateBoxUseCase: UpdateBoxUseCase,
  deleteBoxUseCase: DeleteBoxUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const [onlineDuration, setOnlineDuration] = useState("");
  const [offlineDuration, setOfflineDuration] = useState("");
  const [box, setBox] = useState<Box | undefined>();
  const navigate = useNavigate();

  const { sendMessage, lastMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: false,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage.data == "PING") {
        sendMessage("PONG");
        return;
      }
      const returnMessage: any = JSON.parse(lastMessage.data);
      if (returnMessage.boxId !== box?._id) return;
      if (
        returnMessage.type === WebsocketModelType.RETURN_ONLINE_OFFLINE_STATUS
      ) {
        if (returnMessage.lastOnlineDate !== undefined) {
          setBox({
            ...box,
            lastOnlineDate: returnMessage.lastOnlineDate,
            isOnline: returnMessage.isOnline,
          });
        }
        if (returnMessage.firstOnlineDate !== undefined)
          setBox({
            ...box,
            firstOnlineDate: returnMessage.firstOnlineDate,
            isOnline: returnMessage.isOnline,
          });

        if (returnMessage.installedApps !== undefined) {
          setBox({
            ...box,
            installedApps: returnMessage.installedApps,
          });
        }
      }
      console.log(lastMessage.data);
    }
  }, [lastMessage]);

  useEffect(() => {
    setOnlineDuration(
      calculateTimeDifferenceOfFirstOnlineAndLastOnlineDates(
        box?.firstOnlineDate,
        box?.lastOnlineDate
      )
    );

    setOfflineDuration(
      calculateTimeDifferenceOfFirstOnlineAndLastOnlineDates(
        box?.lastOnlineDate,
        new Date()
      )
    );
  }, [box?.lastOnlineDate]);

  const MINUTE_MS = 60 * 1000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (box?.lastOnlineDate)
        setOfflineDuration(
          calculateTimeDifferenceOfFirstOnlineAndLastOnlineDates(
            box.lastOnlineDate,
            new Date()
          )
        );
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const message: string = JSON.stringify({
        role: "admin",
        boxId: box?._id,
      });
      sendMessage(message);
      console.log(message);
    }
  }, [box?._id]);

  useEffect(() => {
    if (box?._id)
      if (readyState === ReadyState.OPEN) {
        const message: string = JSON.stringify({
          role: "admin",
          boxId: box._id,
        });
        sendMessage(message);
        console.log(message);
      }
  }, [readyState]);

  async function getBox(boxId: string) {
    setLoading(true);
    window.scrollTo(0, 0);
    const response = await getBoxUseCase.invoke(boxId);
    switch (response.responseState) {
      case State.Success:
        setBox(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setLoading(false);
  }

  async function updateBox() {
    if (!box) return;
    const response = await updateBoxUseCase.invoke({
      name: box.name,
      _id: box._id,
    });
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
  }

  async function deleteBox() {
    if (!box?._id) return;
    setIsBackdropLoading(true);
    const response = await deleteBoxUseCase.invoke(box._id);
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        navigate(ROUTE_ENDPOINTS.HOME_BOXES);
        navigate(0);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsBackdropLoading(false);
  }

  function updateBoxName(name: string) {
    setBox({ ...box, name: name });
  }

  function calculateTimeDifferenceOfFirstOnlineAndLastOnlineDates(
    startingDate?: Date,
    endDate?: Date
  ): string {
    const startDate = moment(startingDate);
    const timeEnd = moment(endDate);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    return diffDuration.humanize();
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    onlineDuration,
    isLoading,
    box,
    getBox,
    updateBoxName,
    updateBox,
    offlineDuration,
    deleteBox,
    isBackdropLoading,
  };
}
