import { useNavigate, useParams } from "react-router-dom";
import { BoxDetailsViewModel } from "./BoxDetailsViewModel";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  keyframes,
  Stack,
  Toolbar,
} from "@mui/material";
import {
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_SECONDARY,
  COLOR_VERY_LIGHT_GRAY,
  COLOR_WHITE,
} from "../../../../data/colors/Colors";
import { LoadingView } from "../../../component/LoadingView";
import { useEffect, useState } from "react";
import { CustomTextView } from "../../../component/CustomTextView";
import {
  CheckCircle,
  Circle,
  Delete,
  DeleteForever,
  Edit,
  EditRounded,
  OpenInBrowser,
  Refresh,
} from "@mui/icons-material";
import { CustomCardView } from "../../../component/CustomCardView";
import { CustomTitledView } from "../../../component/CustomTitledView";
import { CustomTextField } from "../../../component/CustomTextField";
import {
  STRINGS_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_BOX,
  STRINGS_AVAILABILITY,
  STRINGS_BOX_DETAILS,
  STRINGS_DELETE,
  STRINGS_DELETE_BOX,
  STRINGS_EDIT_NAME,
  STRINGS_INSTALLED_APPS,
  STRINGS_IP_ADDRESS,
  STRINGS_NAME,
  STRINGS_OFFLINE,
  STRINGS_ONLINE,
  STRINGS_REFRESH,
  STRINGS_REGISTRATION_DATE,
} from "../../../../data/strings/Strings";
import AutoHeight from "../../../component/AutoHeightView";
import { formatDate } from "../../../../core/utils/Utils";
import { InstalledAppsListItemView } from "../../installedApps/InstalledAppsListItemView";
import useWindowDimensions from "../../../../core/utils/UseWindowDimensions";
import {
  provideDeleteBoxUseCase,
  provideGetBoxUseCase,
  provideUpdateBoxUseCase,
} from "../../../di/UseCasesModule";
import { CustomAlertDialog } from "../../../component/CustomAlertDialog";
import moment from "moment";

const blink = keyframes`
  from { opacity: 0.2; }
  to { opacity: 1; }
`;

export function BoxDetailsView() {
  const { id } = useParams();
  const viewModel = BoxDetailsViewModel(
    provideGetBoxUseCase(),
    provideUpdateBoxUseCase(),
    provideDeleteBoxUseCase()
  );
  const [nameEditable, setNameEditable] = useState(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!id) return;
    viewModel.getBox(id);
  }, [id]);

  return (
    <Stack sx={{ backgroundColor: COLOR_MAIN }} height={"100%"}>
      <CustomAlertDialog
        title={STRINGS_DELETE_BOX}
        description={STRINGS_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_BOX}
        open={showDeleteAlertDialog}
        handleNegativeButtonClick={() => setShowDeleteAlertDialog(false)}
        handlePositiveButtonClick={() => {
          viewModel.deleteBox();
          setShowDeleteAlertDialog(false);
        }}
      />
      <Backdrop
        sx={{ color: COLOR_WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewModel.isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ minHeight: "100vh" }}>
        {viewModel.isLoading ? (
          <LoadingView minHeight="90vh" />
        ) : (
          <>
            {viewModel.box && (
              <Container maxWidth={false}>
                <Grid container spacing={2} p={2} maxWidth={"95vw"}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    width={"100%"}
                  >
                    <CustomCardView
                      background={COLOR_SECONDARY}
                      padding={3}
                      children={
                        <Stack
                          direction={{ xs: "column", sm: "column", xl: "row" }}
                          width={"100%"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          spacing={2}
                        >
                          <CustomTextView
                            text={STRINGS_BOX_DETAILS}
                            textColor={COLOR_WHITE}
                            variant="h4"
                            fontWeight="bold"
                          />
                          <Button
                            onClick={() => {
                              setShowDeleteAlertDialog(true);
                            }}
                            variant="outlined"
                            sx={{
                              borderRadius: 12,
                              borderColor: "red",
                              "&:hover": {
                                borderColor: COLOR_MAIN,
                              },
                            }}
                          >
                            <CustomTextView
                              text={STRINGS_DELETE}
                              textColor={"red"}
                              fontSize={14}
                              fontWeight="300"
                              props={{ textTransform: "none" }}
                            />
                            <Delete
                              sx={{ color: "red", ml: 1 }}
                              fontSize="small"
                            />
                          </Button>
                        </Stack>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <AutoHeight>
                      <CustomTitledView
                        title={STRINGS_NAME}
                        hasDivider
                        icon={
                          nameEditable ? (
                            <CheckCircle
                              fontSize="medium"
                              sx={{ color: COLOR_WHITE }}
                            />
                          ) : (
                            <EditRounded
                              fontSize="medium"
                              sx={{ color: COLOR_WHITE }}
                            />
                          )
                        }
                        onIconClicked={() => {
                          if (
                            viewModel.box?.name &&
                            viewModel.box?.name.length === 0
                          )
                            return;
                          setNameEditable(!nameEditable);
                          if (nameEditable) viewModel.updateBox();
                        }}
                        layout={
                          <Stack justifyContent={"center"} minHeight={140}>
                            {nameEditable ? (
                              <CustomTextField
                                value={viewModel.box.name}
                                fontSize={30}
                                textColor={COLOR_WHITE}
                                fontWeight="bold"
                                backgroundColor={"transparent"}
                                placeholder={STRINGS_EDIT_NAME}
                                variant="standard"
                                onTextChange={viewModel.updateBoxName}
                                sxProps={{
                                  pl: 3,
                                  pr: 3,
                                  pt: 3,
                                  pb: 3,
                                }}
                              />
                            ) : (
                              <CustomTextView
                                text={viewModel.box.name}
                                fontWeight="bold"
                                fontSize={30}
                                textColor={COLOR_WHITE}
                                props={{ pl: 3, pr: 3, pt: 3, pb: 3 }}
                              />
                            )}
                          </Stack>
                        }
                      />
                    </AutoHeight>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <CustomTitledView
                      title={STRINGS_AVAILABILITY}
                      hasDivider
                      icon={<Refresh sx={{ color: COLOR_WHITE }} />}
                      onIconClicked={() => {
                        navigate(0);
                      }}
                      layout={
                        <Stack
                          direction={"column"}
                          p={3}
                          spacing={0}
                          justifyContent={"center"}
                          minHeight={140}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={2}
                          >
                            <Circle
                              sx={{
                                color: viewModel.box.isOnline ? "green" : "red",
                                animation: viewModel.box.isOnline
                                  ? `${blink} 1.2s linear infinite`
                                  : undefined,
                              }}
                              fontSize="small"
                            />
                            <CustomTextView
                              text={
                                viewModel.box.isOnline
                                  ? STRINGS_ONLINE
                                  : STRINGS_OFFLINE
                              }
                              fontWeight="bold"
                              fontSize={30}
                              textColor={COLOR_WHITE}
                            />
                          </Stack>
                          {!viewModel.box.isOnline && (
                            <Stack>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                              >
                                <CustomTextView
                                  text={"duration of last online:"}
                                  fontWeight="300"
                                  fontSize={14}
                                  textColor={COLOR_LIGHT_GRAY}
                                />
                                <CustomTextView
                                  text={viewModel.onlineDuration}
                                  fontWeight="300"
                                  fontSize={16}
                                  textColor={COLOR_VERY_LIGHT_GRAY}
                                />
                              </Stack>

                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                              >
                                <CustomTextView
                                  text={"been offline for:"}
                                  fontWeight="300"
                                  fontSize={14}
                                  textColor={COLOR_LIGHT_GRAY}
                                />
                                <CustomTextView
                                  text={viewModel.offlineDuration}
                                  fontWeight="300"
                                  fontSize={16}
                                  textColor={COLOR_VERY_LIGHT_GRAY}
                                />
                              </Stack>
                            </Stack>
                          )}
                        </Stack>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <CustomTitledView
                      title={STRINGS_IP_ADDRESS}
                      hasDivider
                      icon={<OpenInBrowser sx={{ color: "transparent" }} />}
                      layout={
                        <Stack
                          direction={"column"}
                          p={3}
                          spacing={0}
                          justifyContent={"center"}
                          minHeight={140}
                        >
                          <CustomTextView
                            text={viewModel.box.ip}
                            fontWeight="bold"
                            fontSize={30}
                            textColor={COLOR_WHITE}
                          />
                        </Stack>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <CustomTitledView
                      title={STRINGS_REGISTRATION_DATE}
                      hasDivider
                      icon={<OpenInBrowser sx={{ color: "transparent" }} />}
                      layout={
                        <Stack
                          direction={"column"}
                          p={3}
                          spacing={0}
                          justifyContent={"center"}
                          minHeight={140}
                        >
                          <CustomTextView
                            text={formatDate(
                              viewModel.box.createdAt ?? new Date()
                            )}
                            fontWeight="bold"
                            fontSize={30}
                            textColor={COLOR_WHITE}
                          />
                        </Stack>
                      }
                    />
                  </Grid>

                  {viewModel.box.installedApps &&
                    viewModel.box.installedApps.length > 0 && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomTitledView
                          title={STRINGS_INSTALLED_APPS}
                          hasDivider
                          layout={
                            <Grid container spacing={2} p={2}>
                              {viewModel.box.installedApps
                                .sort((a, b) => {
                                  if (!a.name) return 0;
                                  if (!b.name) return 0;
                                  var textA = a.name.toUpperCase();
                                  var textB = b.name.toUpperCase();
                                  return textA < textB
                                    ? -1
                                    : textA > textB
                                    ? 1
                                    : 0;
                                })
                                .map((installedApp) => {
                                  return (
                                    <Grid
                                      item
                                      key={installedApp._id}
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={4}
                                      xl={3}
                                    >
                                      <InstalledAppsListItemView
                                        installedApp={installedApp}
                                      />
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </Container>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
}
