export interface Box {
  _id?: string;
  name?: string;
  ip?: string;
  isOnline?: boolean;
  firstOnlineDate?: Date;
  lastOnlineDate?: Date;
  installedApps?: Array<InstalledApp>;
  createdAt?: Date;
}

export interface InstalledApp {
  _id?: string;
  name?: string;
  version?: string;
  image?: string;
  packageName?: string;
}

export interface WebsocketReturnModel {
  isOnline?: boolean
  firstOnlineDate?: Date
  lastOnlineDate?: Date
  boxId?: string
  type: WebsocketModelType
}

export enum WebsocketModelType {
  LISTENING_TO_ONLINE_OFFLINE_STATUS = "LISTENING_TO_ONLINE_OFFLINE_STATUS",
  RETURN_ONLINE_OFFLINE_STATUS = "RETURN_ONLINE_OFFLINE_STATUS",
}

export const sampleBoxes: Array<Box> = [
  {
    _id: "adasdasdad12uh3iou12jn3aqe81h30j21r",
    name: "Box 1",
    isOnline: true,
    lastOnlineDate: new Date(),
    installedApps: [
      {
        _id: "1982i0912j3io21m3aedojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae12omiom32dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
      {
        _id: "1982i1kjn23aedojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae1232j21n3kn12dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
      {
        _id: "1982iaedojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae112io3n21klasdasd232dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
      {
        _id: "123i12j3oi12n3j2130821kk1221i3n",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae123901j2093j12io3n2dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
    ],
    createdAt: new Date(),
  },
  {
    _id: "adasda12j3io12j3s30j21r",
    name: "Box 2",
    isOnline: true,
    lastOnlineDate: new Date(),
    createdAt: new Date(),
  },
  {
    _id: "aasdadaqe8awsdad1h30j21r",
    name: "Box 3",
    isOnline: false,
    lastOnlineDate: new Date(),
    createdAt: new Date(),
  },
  {
    _id: "adasdadakjn12jk3qe81h30j21r",
    name: "Box 4",
    isOnline: false,
    lastOnlineDate: new Date(),
    createdAt: new Date(),
    installedApps: [
      {
        _id: "1982iae1092u3j912dojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae120-3k1231232dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
      {
        _id: "1982i213lkm213aedojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae1i123m232j1n332dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
      {
        _id: "1981232132iaed12oi3m21ojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1982iae1212m3k21331232132dojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
      {
        _id: "1982123iaeoi12j3i0j213dojd",
        name: "Chrome",
        version: "2.0.1",
        packageName: "com.google.chrome",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/640px-Google_Chrome_icon_%28February_2022%29.svg.png",
      },
      {
        _id: "1912k3m12kom382iae1232d123123ojd",
        name: "Google Maps",
        version: "4.2.1",
        packageName: "com.google.maps",
        image:
          "https://cdn.vox-cdn.com/thumbor/KqYnd6YgxZ6yJvyBCk-heGXYEMM=/0x0:1280x800/1400x1400/filters:focal(640x400:641x401)/cdn.vox-cdn.com/uploads/chorus_asset/file/19700731/googlemaps.png",
      },
    ],
  },
];

export function getBoxFromId(id: string) {
  return sampleBoxes.filter((b) => b._id === id)[0];
}
