import { Stack, Toolbar } from "@mui/material";
import { CustomImageView } from "../../component/CustomImageView";
import { COLOR_MAIN, COLOR_WHITE } from "../../../data/colors/Colors";
import { CustomTextView } from "../../component/CustomTextView";

export function NoBoxSelectedView() {
  return (
    <Stack
      height={"100vh"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ background: COLOR_MAIN }}
      spacing={2}
    >
      <CustomImageView
        src={require("../../../assets/images/choose-android-box-cartoonish-image.png")}
        width="100px"
        height="100px"
        props={{ borderRadius: 12 }}
      />
      <CustomTextView
        text="Select a box to see its details"
        textColor={COLOR_WHITE}
        fontWeight="bold"
        variant="h5"
      />
      <Toolbar />
    </Stack>
  );
}
