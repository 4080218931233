import { CustomCardView } from "./CustomCardView";
import { Divider, IconButton, Stack } from "@mui/material";
import { CustomTextView } from "./CustomTextView";
import { ReactElement } from "react";
import {
  COLOR_LIGHT_GRAY,
  COLOR_SECONDARY,
  COLOR_WHITE,
} from "../../data/colors/Colors";

interface props {
  title: string;
  icon?: ReactElement;
  onIconClicked?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  height?: number;
  minHeight?: number;
  layout: ReactElement;
  hasDivider?: boolean;
}

export const CustomTitledView: React.FC<props> = ({
  title,
  icon,
  onIconClicked,
  height,
  layout,
  hasDivider = true,
  minHeight,
}: props) => {
  return (
    <CustomCardView
      padding={0}
      height={height}
      minHeight={minHeight}
      background={COLOR_SECONDARY}
      children={
        <Stack
          spacing={0}
          height={"100%"}
          justifyContent={"space-between"}
          display={"flex"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={3}
          >
            <CustomTextView
              text={title}
              fontWeight="600"
              variant="h6"
              textColor={COLOR_LIGHT_GRAY}
            />
            {icon && <IconButton onClick={onIconClicked}>{icon}</IconButton>}
          </Stack>
          {hasDivider && <Divider sx={{ background: COLOR_LIGHT_GRAY }} />}
          {layout}
        </Stack>
      }
    />
  );
};
