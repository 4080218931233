import { Box, Grid, Stack, Toolbar } from "@mui/material";
import { Box as BoxModel } from "../../../domain/model/Box";
import { BoxesListItemView } from "../box/BoxesListItemView";
import {
  COLOR_MAIN,
  COLOR_SECONDARY,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { CustomTextView } from "../../component/CustomTextView";
import {
  STRINGS_BOXES,
  STRINGS_NOTHING_TO_SHOW,
  STRINGS_SEARCH,
} from "../../../data/strings/Strings";
import {
  HourglassEmpty,
  HourglassEmptyRounded,
  Search,
} from "@mui/icons-material";
import { CustomTextField } from "../../component/CustomTextField";
import { LoadingView } from "../../component/LoadingView";
import { useState } from "react";

interface p {
  boxes: Array<BoxModel>;
  isLoading: boolean;
  handleToggleDrawer?: () => void;
  searchBoxes: (name: string) => void;
}
export function DrawerView(p: p) {
  const [searchText, setSearchText] = useState("");
  return (
    <Stack p={2} sx={{ background: COLOR_MAIN }} display={"flex"} flex={1}>
      <Toolbar />
      <CustomTextView
        text={STRINGS_BOXES}
        variant="h4"
        textColor={COLOR_WHITE}
        fontWeight="bold"
      />
      <Box
        component={"form"}
        sx={{ mt: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          p.searchBoxes(searchText);
        }}
      >
        <CustomTextField
          type="search"
          value={searchText}
          placeholder={STRINGS_SEARCH}
          onTextChange={setSearchText}
          textColor={COLOR_WHITE}
          borderColor={COLOR_SECONDARY}
          backgroundColor={COLOR_SECONDARY}
          startIcon={<Search sx={{ color: COLOR_WHITE }} />}
        />
      </Box>
      {p.isLoading ? (
        <LoadingView />
      ) : (
        <>
          {p.boxes && p.boxes.length > 0 ? (
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
              {p.boxes.map((box, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    key={box._id}
                  >
                    <BoxesListItemView
                      box={box}
                      index={index}
                      handleToggleDrawer={p.handleToggleDrawer}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Stack width={"100%"} alignItems={"center"} mt={12} spacing={2}>
              <HourglassEmptyRounded
                sx={{ color: COLOR_WHITE }}
                fontSize="large"
              />
              <CustomTextView
                text={STRINGS_NOTHING_TO_SHOW}
                textColor={COLOR_WHITE}
                fontWeight="bold"
                variant="h5"
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
