import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import { STRINGS_CANCEL, STRINGS_OK } from "../../data/strings/Strings";
import { CustomTextView } from "./CustomTextView";
import { COLOR_MAIN, COLOR_WHITE } from "../../data/colors/Colors";

interface AlertDialogControls {
  open: boolean;
  title: string;
  description: string;
  handleNegativeButtonClick: () => void;
  handlePositiveButtonClick: () => void;
}

export const CustomAlertDialog: React.FC<AlertDialogControls> = ({
  open,
  title,
  description,
  handleNegativeButtonClick,
  handlePositiveButtonClick,
}: AlertDialogControls) => {
  return (
    <Dialog
      open={open}
      onClose={handleNegativeButtonClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { borderRadius: 16, background: COLOR_MAIN } }}
    >
      <DialogTitle id="alert-dialog-title">
        <CustomTextView
          variant="h5"
          text={title}
          fontWeight="bold"
          textColor={COLOR_WHITE}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
        >
        <CustomTextView
          variant="h6"
          text={description}
          fontWeight="300"
          textColor={COLOR_WHITE}
        />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNegativeButtonClick}>
          <CustomTextView
            text={STRINGS_CANCEL}
            fontWeight="300"
            textColor={COLOR_WHITE}
          />
        </Button>
        <Button onClick={handlePositiveButtonClick} autoFocus>
          <CustomTextView
            text={STRINGS_OK}
            fontWeight="bold"
            textColor={COLOR_WHITE}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
