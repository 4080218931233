import { Message } from "../../../domain/model/Auth";
import { Box } from "../../../domain/model/Box";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { BoxRepository } from "./BoxRepository";

export class BoxRepositoryImpl implements BoxRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }

  getAll(): Promise<Response<Array<Box>>> {
    return this.apiService.getAllBoxes();
  }
  get(id: string): Promise<Response<Box>> {
    return this.apiService.getBox(id);
  }
  delete(id: string): Promise<Response<Message>> {
    return this.apiService.deleteBox(id);
  }
  update(box: Box): Promise<Response<Message>> {
    return this.apiService.updateBox(box);
  }
  search(name: string): Promise<Response<Array<Box>>> {
    return this.apiService.searchBoxes(name);
  }
}
